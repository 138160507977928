import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Pod, PodStatus } from '../types/pod';
import { format } from 'date-fns';

export interface Availability {
  from: string,
  to: string,
  isAvailable: boolean,
}
@Injectable({
  providedIn: 'root'
})
export class PodService {

  constructor(
    private httpClient: HttpClient,
  ) { }

  list(available: boolean = null): Observable<Pod[]> {
    return this.httpClient.get<Pod[]>(`${environment.apiUrl}pod${available?'?available=true':''}`);
  }

  status(pod: Pod): Observable<PodStatus> {
    return this.httpClient.get<PodStatus>(`${environment.apiUrl}pod/${pod.uuid}/status`);
  }

  calendar(pod: Pod, day: Date): Observable<Availability[]> {
    return this.httpClient.get<Availability[]>(`${environment.apiUrl}pod/${pod.uuid}/calendar?date=${format(day, 'dd-MM-yyyy')}`)
  }
}
