import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'kabin-collapse',
  templateUrl: './collapse.component.html',
  styleUrls: ['./collapse.component.scss'],
})
export class CollapseComponent implements OnInit {

  @Input() color = 'default';
  @Input() icon: string;
  @Input() title: string;
  @Input() showArrow: boolean;
  @Input() opened: boolean;

  collapsed = true;

  constructor() { }

  ngOnInit() {
    this.showArrow = this.showArrow !== undefined;
    this.opened = this.opened !== undefined;
  }

  collapse() {
    this.opened = ! this.opened;
  }

}
