import { createAction, props } from "@ngrx/store";
import { Booking, BookingCreatePayload } from "src/app/types/booking";
import { Pod } from "src/app/types/pod";
import { BookingSelection } from "src/app/types/booking";
import { HttpErrorResponse } from "@angular/common/http";
import { BillingAddress, Coupon, PaymentMethod } from "src/app/types/payment";

export const SET_CURRENT = '[Booking] Set Current';

export const CREATE_BOOKING = '[Booking] Create Booking';
export const CREATE_BOOKING_SUCCESS = '[Booking] Create Booking Success';
export const CREATE_BOOKING_FAILURE = '[Booking] Create Booking Failure';

export const ACCESS_BOOKING = '[Booking] Access Booking';
export const ACCESS_BOOKING_SUCCESS = '[Booking] Access Booking Success';
export const ACCESS_BOOKING_FAILURE = '[Booking] Access Booking Failure';

export const SET_POD = '[Booking] Set Pod';
export const SET_COUPON = '[Booking] Set Coupon';
export const SET_START = '[Booking] Set Start';
export const SET_END = '[Booking] Set End';
export const SET_SELECTED_PAYMENT_METHOD = '[Booking] Set Selected Payment';
export const SET_SELECTED_BILLING_ADDRESS = '[Booking] Set Selected Billing';
export const CLEAR_BOOKING_STATE = '[Booking] Clear Booking State';

export const setCurrent = createAction(
  SET_CURRENT,
  props<{
    selection: BookingSelection,
    pod: Pod,
  }>()
);

export const createBooking = createAction(
  CREATE_BOOKING,
);

export const createBookingSuccess = createAction(
  CREATE_BOOKING_SUCCESS,
  props<{
    booking: Booking,
  }>()
);

export const createBookingFailure = createAction(
  CREATE_BOOKING_FAILURE,
  props<{ error: HttpErrorResponse }>()
);

export const accessBooking = createAction(
  ACCESS_BOOKING,
  props<{ booking: Booking }>()
);

export const accessBookingSuccess = createAction(
  ACCESS_BOOKING_SUCCESS,
  props<{ booking: Booking }>()
);

export const accessBookingFailure = createAction(
  ACCESS_BOOKING_FAILURE,
  props<{ error: HttpErrorResponse }>()
);

export const setPod = createAction(
  SET_POD,
  props<{
    pod: Pod,
  }>()
);

export const setCoupon = createAction(
  SET_COUPON,
  props<{
    coupon: Coupon,
  }>()
);

export const setStart = createAction(
  SET_START,
  props<{
    start: Date,
  }>()
);

export const setEnd = createAction(
  SET_END,
  props<{
    end: Date,
  }>()
);

export const setSelectedPaymentMethod = createAction(
  SET_SELECTED_PAYMENT_METHOD,
  props<{
    selected: PaymentMethod,
  }>()
);

export const setSelectedBillingAddress = createAction(
  SET_SELECTED_BILLING_ADDRESS,
  props<{
    selected: BillingAddress,
  }>()
);


export const clearBookingState = createAction(
  CLEAR_BOOKING_STATE,
);