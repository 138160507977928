import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { Pod } from "src/app/types/pod";

export const LOAD_PODS = '[Pod] Load Pods';
export const LOAD_PODS_SUCCESS = '[Pod] Load Pods Success';
export const LOAD_PODS_FAILURE = '[Pod] Load Pods Failure';

export const TOGGLE_POD_DISPLAY = '[Pod] Toggle Pod Display';

export const loadPods = createAction(
  LOAD_PODS,
);

export const loadPodsSuccess = createAction(
  LOAD_PODS_SUCCESS,
  props<{
    pods: Pod[]
  }>()
);

export const loadPodsFailure = createAction(
  LOAD_PODS_FAILURE,
  props<{
    error: any | HttpErrorResponse;
  }>()
);

export const togglePodDisplay = createAction(
  TOGGLE_POD_DISPLAY
);
