import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { NotificationOptions } from '../types/global';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  public title = '';
  public body;
  public type = 'success';
  public link = null;
  public showClose = false;
  public icon;
  public apiError = false;

  private isVisible: BehaviorSubject<Boolean> = new BehaviorSubject(false);

  constructor() { }

  getVisibility() {
    return this.isVisible.asObservable()
  }

  setVisibility(isVisible: boolean) {
    this.isVisible.next(isVisible)
  }

  notify(options: NotificationOptions) {
    this.title = options.title;
    this.body = options.body;
    if (options.apiError) {
      this.body = this.getApiError();
    } else {
      this.body = options.body;
    }
    this.type = options.type;
    this.link = options.link;
    this.showClose = options.showClose;
    this.icon = options.icon;
    this.apiError = options.apiError;
    this.isVisible.next(true)
    setTimeout(() => {
      if (this.isVisible.value) {
        this.setVisibility(false)
      }
    }, 4000);
  }

  getApiError() {
    if (typeof this.body.error === 'string') {
      return this.body.error;
    } else if (typeof this.body.error === 'object') {
      let string = "";
      Object.keys(this.body.error.errors).forEach(key => {
        string += this.body.error.errors[key].value + ' ';
      });
      return string;
    } else {
      return this.body;
    }
  }

  getData() {
    return {
      title: this.title,
      body: this.body,
      type: this.type,
      link: this.link
    };
  }
}
