import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { Booking } from 'src/app/types/booking';
import { selectCurrentBooking } from 'src/app/store/selectors/ride.selectors';

@Component({
  selector: 'kabin-ride-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent  implements OnInit {

  @Input() isInAutomaticBuffer: BehaviorSubject<boolean>
  booking$: Observable<Booking>

  constructor(
    private store: Store,
  ) {
    this.booking$ = this.store.select(selectCurrentBooking)
  }

  ngOnInit() {
    //
  }
}
