import { Injectable } from "@angular/core";
import { ModalController, NavController } from "@ionic/angular";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { of } from "rxjs";
import { catchError, exhaust, exhaustMap, map, tap, withLatestFrom } from "rxjs/operators";
import { Booking, BookingCreatePayload } from "src/app/types/booking";
import { BookingService } from "src/app/services/booking.service";
import { NotificationService } from "src/app/services/notification.service";
import { loadPods } from '../actions/pod.actions';
import {
  CREATE_BOOKING,
  createBookingSuccess,
  createBookingFailure,
  CREATE_BOOKING_SUCCESS,
  ACCESS_BOOKING,
  accessBookingSuccess,
  accessBookingFailure,
  SET_START,
  SET_END,
} from "../actions/booking.actions";
import { HttpErrorResponse } from "@angular/common/http";
import { loadCurrentBooking, openKabinSuccess } from "../actions/ride.actions";
import { loadNextBookings } from "../actions/me.actions";
import { RideService } from "src/app/services/ride.service";
import { Router } from "@angular/router";
import { Coupon } from "src/app/types/payment";
import { selectBookingStore } from "../selectors/booking.selectors";
import { BookingState } from "../reducers/booking.reducer";

@Injectable()
export class BookingEffects {
  createBooking$ = createEffect(() => 
    this.actions$.pipe(
      ofType(CREATE_BOOKING),
      withLatestFrom(
        this.store.select(selectBookingStore)
      ),
      exhaustMap(([_, booking]: [any, BookingState]) => {
        const payload: any = {
          booking_start_at: booking.start,
          booking_end_at: booking.end,
          pod: booking.pod.uuid,
        };
    
        if (booking.selectedPaymentMethod) {
          payload.payment_method_id = booking.selectedPaymentMethod.id
        }
    
        if (booking.selectedBillingAddress) {
          payload.billing_address_uuid = booking.selectedBillingAddress.uuid
        }
    
        if (booking.coupon) {
          payload.discount_uuid = booking.coupon.uuid;
        }
        return this.bookingService.create(payload).pipe(
          map(({ booking }: { booking: Booking }) => createBookingSuccess({ booking })),
          catchError((error: any) => of(createBookingFailure({ error }))),
        )
      })
    )
  );

  createBookingSuccessDismissModal$ = createEffect(() => 
    this.actions$.pipe(
      ofType(CREATE_BOOKING_SUCCESS),
      tap(() => {
        this.modalController.dismiss({
          isBookingDone: true,
          dismissed: true,
        });
      })
    )
  , {
    dispatch: false
  });

  onBookingCreateReloadPods$ = createEffect(() => 
    this.actions$.pipe(
      ofType(CREATE_BOOKING_SUCCESS),
      map(() => loadPods()),
    )
  );

  onBookingCreateReloadNextBookings$ = createEffect(() => 
    this.actions$.pipe(
      ofType(CREATE_BOOKING_SUCCESS),
      map(() => loadNextBookings()),
    )
  );

  onBookingCreateLoadCurrent$ = createEffect(() => 
    this.actions$.pipe(
      ofType(CREATE_BOOKING_SUCCESS),
      map(() => loadCurrentBooking()),
    )
  );

  accessBooking$ = createEffect(() => 
    this.actions$.pipe(
      ofType(ACCESS_BOOKING),
      exhaustMap(({ booking }: { booking: Booking }) => 
        this.rideSerivce.access(booking).pipe(
          map(({ booking }: { booking: Booking }) => openKabinSuccess({ booking })),
          catchError((error: any) => of(accessBookingFailure({ error }))),
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private store: Store,
    private bookingService: BookingService,
    private modalController: ModalController,
    private notificationService: NotificationService,
    private navController: NavController,
    private rideSerivce: RideService,
    private router: Router
  ) { }
}
