import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Booking } from 'src/app/types/booking';
import { addSeconds, differenceInSeconds, intervalToDuration, parseISO, startOfDay, subMinutes } from 'date-fns';
import { BehaviorSubject, Observable, combineLatest, forkJoin, interval, of, timer } from 'rxjs';
import { distinctUntilChanged, filter, map, mergeMap, switchMap, take, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { selectCurrentBookingDynamicBufferEndAt } from 'src/app/store/selectors/ride.selectors';
import { isNil as _isNil } from 'lodash';
import { App } from '@capacitor/app';

@Component({
  selector: 'kabin-ride-header-timer',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.scss'],
})
export class TimerComponent  implements OnInit {

  @Input() booking$: Observable<Booking>;
  @Input() isInAutomaticBuffer: BehaviorSubject<boolean>
  countdown$: Observable<number>
  display$: Observable<string>
  inAutomaticBuffer$: Observable<boolean>
  secondsLeft$: Observable<number>;
  currentBookingDynamicBufferEndAt$: Observable<string>

  constructor(
    private store: Store,
  ) {
    this.currentBookingDynamicBufferEndAt$ = this.store.select(selectCurrentBookingDynamicBufferEndAt)
    this.buildObservables()
    App.addListener('appStateChange', ({ isActive }) => {
      if(isActive) {
        this.buildObservables()
      }
    });
  }

  buildObservables = () => {
    this.secondsLeft$ = this.currentBookingDynamicBufferEndAt$.pipe(
      filter((value: string) => !_isNil(value)),
      map((value: string) => differenceInSeconds(parseISO(value), new Date()))
    )
    this.countdown$ = this.secondsLeft$.pipe(
      switchMap((secondsLeft: number) => timer(0, 1000).pipe(
        map((counter: number) => secondsLeft - counter),
      ))
    )
    this.display$ = this.countdown$.pipe(
      map((x: number) => this.secondsToTimer(x))
    )
  }

  ngOnInit() {
    this.countdown$.pipe(
      map((countdown: number) => countdown <= 0),
      distinctUntilChanged()
    ).subscribe((value: boolean) => {
      this.isInAutomaticBuffer.next(value)
    })
  }

  secondsToTimer(s: number) {
    s = Math.abs(s)
    const { hours, minutes, seconds } = intervalToDuration({ start: 0, end: s * 1000 })
    let display = ''
    if (hours > 0) {
      display += `${this.zeroPad(hours)}:`
    }
    display += `${this.zeroPad(minutes)}:${this.zeroPad(seconds)}`
    return display
  }

  zeroPad(num: number) {
    return String(num).padStart(2, '0')
  }

}
