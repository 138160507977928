import { createAction, props } from '@ngrx/store';
import { Booking, ExtendPayload } from 'src/app/types/booking';
import { Pod, PodStatus } from 'src/app/types/pod';

export const LOAD_CURRENT_BOOKING = '[Ride] Load Current Booking';
export const LOAD_CURRENT_BOOKING_SUCCESS = '[Ride] Load Current Booking Success';
export const LOAD_CURRENT_BOOKING_FAILURE = '[Ride] Load Current Booking Failure';

export const GET_POD_STATUS = '[Ride] Get Pod Status';
export const GET_POD_STATUS_SUCCESS = '[Ride] Get Pod Status Success';
export const GET_POD_STATUS_FAILURE = '[Ride] Get Pod Status Failure';

export const SET_BOOKING_STATUS = '[Ride] Set Booking Status';

export const OPEN_KABIN = '[Ride] Open Kabin';
export const OPEN_KABIN_SUCCESS = '[Ride] Open Kabin Success';
export const OPEN_KABIN_FAILURE = '[Ride] Open Kabin Failure';

export const COMPLETE_CHECKIN = '[Ride] Complete Checkin';
export const COMPLETE_CHECKIN_SUCCESS_WITH_PRE_RIDE = '[Ride] Complete Checkin Success With Pre Ride';
export const COMPLETE_CHECKIN_SUCCESS = '[Ride] Complete Checkin Success';
export const COMPLETE_CHECKIN_FAILURE = '[Ride] Complete Checkin Failure';

export const COMPLETE_RIDE = '[Ride] Complete Ride';
export const COMPLETE_RIDE_SUCCESS = '[Ride] Complete Ride Success';
export const COMPLETE_RIDE_FAILURE = '[Ride] Complete Ride Failure';

export const COMPLETE_CHECKOUT = '[Ride] Complete Checkout';
export const COMPLETE_CHECKOUT_SUCCESS = '[Ride] Complete Checkout Success';
export const COMPLETE_CHECKOUT_FAILURE = '[Ride] Complete Checkout Failure';

export const EXTEND_BOOKING = '[Ride] Extend Booking';
export const EXTEND_BOOKING_SUCCESS = '[Ride] Extend Booking Success';
export const EXTEND_BOOKING_FAILURE = '[Ride] Extend Booking Failure';

export const CLEAR_BOOKING_ERROR = '[Auth] Clear Booking Error';

export const CLEAR_RIDE = '[Ride] Clear Booking';

export const loadCurrentBooking = createAction(
  LOAD_CURRENT_BOOKING
);

export const clearRide = createAction(
  CLEAR_RIDE
);

export const loadCurrentBookingSuccess = createAction(
  LOAD_CURRENT_BOOKING_SUCCESS,
  props<{ booking: Booking }>()
);

export const loadCurrentBookingFailure = createAction(
  LOAD_CURRENT_BOOKING_FAILURE,
  props<any>()
);


export const extendBooking = createAction(
  EXTEND_BOOKING,
  props<ExtendPayload>()
);

export const extendBookingSuccess = createAction(
  EXTEND_BOOKING_SUCCESS,
  props<{ booking: Booking }>()
);

export const extendBookingFailure = createAction(
  EXTEND_BOOKING_FAILURE,
  props<any>()
);

export const getPodStatus = createAction(
  GET_POD_STATUS,
  props<{ pod: Pod }>()
);

export const getPodStatusSuccess = createAction(
  GET_POD_STATUS_SUCCESS,
  props<{ status: PodStatus }>()
);

export const getPodStatusFailure = createAction(
  GET_POD_STATUS_FAILURE,
  props<any>()
);

export const setBookingStatus = createAction(
  SET_BOOKING_STATUS,
  props<{ status: string }>()
);

export const openKabin = createAction(
  OPEN_KABIN,
  props<{ booking: Booking }>()
);

export const openKabinSuccess = createAction(
  OPEN_KABIN_SUCCESS,
  props<{ booking: Booking }>()
);

export const openKabinFailure = createAction(
  OPEN_KABIN_FAILURE,
  props<any>()
);

export const completeCheckin = createAction(
  COMPLETE_CHECKIN,
  props<{ continue: boolean }>()
);

export const completeCheckinSuccess = createAction(
  COMPLETE_CHECKIN_SUCCESS,
  props<{ booking: Booking }>()
);

export const completeCheckinSuccessWithPreRide = createAction(
  COMPLETE_CHECKIN_SUCCESS_WITH_PRE_RIDE,
);

export const completeCheckinFailure = createAction(
  COMPLETE_CHECKIN_FAILURE,
  props<any>()
);

export const completeRide = createAction(
  COMPLETE_RIDE
);

export const completeRideSuccess = createAction(
  COMPLETE_RIDE_SUCCESS,
  props<{ status: string }>()
);

export const completeRideFailure = createAction(
  COMPLETE_RIDE_FAILURE,
  props<any>()
);

export const completeCheckout = createAction(
  COMPLETE_CHECKOUT,
  props<{ reports: any }>()
);

export const completeCheckoutSuccess = createAction(
  COMPLETE_CHECKOUT_SUCCESS,
  props<{ booking: Booking }>()
);

export const completeCheckoutFailure = createAction(
  COMPLETE_CHECKOUT_FAILURE,
  props<any>()
);

export const clearBookingError = createAction(
  CLEAR_BOOKING_ERROR
);
