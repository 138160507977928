import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'kabin-documents',
  templateUrl: './documents.page.html',
  styleUrls: ['./documents.page.scss'],
})
export class DocumentsPage implements OnInit {

  public documents: Array<any>;
  public hasCmi = false;

  constructor(
    public modalCtrl: ModalController,
  ) { }

  ngOnInit() {
    this.documents = [];
    // this.checkCNI();
    // this.checkFaceRecognition();
    this.checkCMI();
  }

  checkCNI() {
    // this.apiService.get('auth/validation/id').subscribe((res: any) => {
    //
    //   console.log({CNI: res})
    //   this.documents.push(res)
    // }, err => {
    //   console.log(err)
    // })
    this.documents.push({
      type: 'CNI',
      position: 'Recto',
      validate: true
    },
    {
      type: 'CNI',
      position: 'Verso',
      validate: false
    });
  }

  checkFaceRecognition() {
    // this.apiService.get('auth/validation/face-recognition').subscribe((res: any) => {
    //   console.log({faceRecognition: res})
    //   this.documents.push(res)
    // }, err => {
    //   console.log(err)
    // })
    this.documents.push({
      type: 'Reconnaissance Faciale',
      position: null,
      validate: false
    });
  }

  checkCMI() {
    this.hasCmi = false;
  }

  chooseCMI(value: boolean) {
    this.hasCmi = value;
  }

  dismiss() {
    this.modalCtrl.dismiss({dismissed: true, hasCmi: this.hasCmi});
  }

}
