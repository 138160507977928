import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { Booking } from 'src/app/types/booking';

@Component({
  selector: 'kabin-ride-header-progress',
  templateUrl: './progress.component.html',
  styleUrls: ['./progress.component.scss'],
})
export class ProgressComponent  implements OnInit {

  @Input() booking$: Observable<Booking>
  status$: Observable<string>

  constructor() {}

  ngOnInit() {
    this.status$ = this.booking$.pipe(
      filter((booking: Booking) => booking !== null),
      map((booking: Booking) => booking.status)
    )
  }

}
