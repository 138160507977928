import { Component, OnInit, Input, HostListener } from '@angular/core';

@Component({
  selector: 'kabin-card-button',
  templateUrl: './card-button.component.html',
  styleUrls: ['./card-button.component.scss'],
})
export class CardButtonComponent implements OnInit {

  @Input() title: string;
  @Input() isEmpty: boolean;
  @Input() clickable: boolean;
  @Input() showArrow: boolean;
  @Input() customArrow: boolean;
  @Input() translate: boolean;

  /*
  @HostListener("click") onClick(){
    console.log("User Click using Host Listener")
  }
  */

  constructor() { }

  ngOnInit() {
    this.clickable = this.clickable !== undefined;
    this.showArrow = this.showArrow !== undefined;
  }

}
