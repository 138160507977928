import { Injectable } from '@angular/core';
import { Booking, ExtendPayload } from '../types/booking';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { Picture } from '../types/global';
import { CheckinReport, CheckinReportPayload } from '../types/ride';

@Injectable({
  providedIn: 'root'
})
export class RideService {

  constructor(
    private httpClient: HttpClient,
  ) { }

  completeCheckin(booking: Booking, data: { continue: boolean }) {
    return this.httpClient.post<{ booking: Booking, ride: boolean }>(`${environment.apiUrl}booking/${booking.uuid}/steps/checkin`, data)
  }

  completeRide(booking: Booking) {
    return this.httpClient.post<{ status: string }>(`${environment.apiUrl}booking/${booking.uuid}/steps/ride`, {})
  }

  completeCheckout(booking: Booking, data: any) { // TODO: check URL 
    return this.httpClient.post(`${environment.apiUrl}booking/${booking.uuid}/steps/checkout`, data)
  }

  access(booking: Booking): Observable<{ booking: Booking}> {
    return this.httpClient.post<{ booking: Booking}>(`${environment.apiUrl}booking/${booking.uuid}/steps/access`, {})
  }

  extend(booking: Booking, data: ExtendPayload): Observable<{ booking: Booking}> {
    return this.httpClient.post<{ booking: Booking}>(`${environment.apiUrl}me/booking/${booking.uuid}/extend`, data)
  }

  addCheckinPicture(booking: Booking, data: FormData) {
    return this.httpClient.post(`${environment.apiUrl}booking/${booking.uuid}/checkin/pictures`, data)
  }

  deleteCheckinPicture(booking: Booking, picture: Picture) {
    return this.httpClient.delete(`${environment.apiUrl}booking/${booking.uuid}/checkin/pictures/${picture.name}`)
  }

  sendCheckinReport(booking: Booking, reports: CheckinReportPayload[]) {
    return this.httpClient.post(`${environment.apiUrl}booking/${booking.uuid}/checkin/report`, { reports })
  }

  addCheckoutPicture(booking: Booking, data: FormData) {
    return this.httpClient.post(`${environment.apiUrl}booking/${booking.uuid}/checkout/pictures`, data)
  }

  deleteCheckoutPicture(booking: Booking, picture: Picture) {
    return this.httpClient.delete(`${environment.apiUrl}booking/${booking.uuid}/checkout/pictures/${picture.name}`)
  }
}
