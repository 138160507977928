import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'kabin-password-popover',
  templateUrl: './password-popover.component.html',
  styleUrls: ['./password-popover.component.scss'],
})
export class PasswordPopoverComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
