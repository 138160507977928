import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { setSelectedBillingAddress } from 'src/app/store/actions/booking.actions';
import { selectSelectedBillingAddress } from 'src/app/store/selectors/booking.selectors';
import { selectBillingAddresses } from 'src/app/store/selectors/me.selector';
import { BillingAddress } from 'src/app/types/payment';

@Component({
  selector: 'kabin-booking-payment-addresses',
  templateUrl: './addresses.component.html',
  styleUrls: ['./addresses.component.scss'],
})
export class AddressesComponent  implements OnInit {

  @Output() onClose = new EventEmitter<void>();
  @Output() openBillingPage = new EventEmitter<void>();

  billingAddresses$: Observable<BillingAddress[]>
  selectedBillingAddress$: Observable<BillingAddress>

  constructor(
    private store: Store
  ) {
    this.billingAddresses$ = this.store.select(selectBillingAddresses)
    this.selectedBillingAddress$ = this.store.select(selectSelectedBillingAddress)
  }

  ngOnInit() {}

  selectBillingAddress(address: BillingAddress) {
    this.store.dispatch(setSelectedBillingAddress({ selected: address }))
  }

}
