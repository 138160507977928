import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { BookingService } from 'src/app/services/booking.service';
import { NotificationService } from 'src/app/services/notification.service';
import { setCoupon } from 'src/app/store/actions/booking.actions';
import { Coupon } from 'src/app/types/payment';

@Component({
  selector: 'kabin-booking-payment-coupon',
  templateUrl: './coupon.component.html',
  styleUrls: ['./coupon.component.scss'],
})
export class CouponComponent  implements OnInit {

  @Output() onClose = new EventEmitter<void>();

  promoCode: string

  constructor(
    private bookingService: BookingService,
    private store: Store,
    private notificationService: NotificationService,
    private translocoService: TranslocoService,
  ) { }

  ngOnInit() {}

  addCoupon(): void {
    this.bookingService.getDiscountCoupon(this.promoCode).subscribe((coupon: Coupon) => {
      this.store.dispatch(setCoupon({ coupon }))
      this.notificationService.notify({
        body: this.translocoService.translate('booking.promo_code.success'),
        icon: 'rocket-outline',
      });
      this.onClose.emit()
    }, err => {
      this.notificationService.notify({
        body: err,
        icon: 'information-circle-outline',
        type: 'danger',
        apiError: true,
      });
    });
  }
}
