import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'kabin-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent implements OnInit {

  @Input() isVisible: boolean;

  showClose: boolean = false;

  constructor(
    public notificationService: NotificationService,
    private router: Router
  ) { }

  ngOnInit() {}

  clickNotification() {
    const data = this.notificationService.getData();
    if (data.link) {
      this.router.navigate([data.link]);
    }
    this.closeNotification();
  }

  closeNotification() {
    this.notificationService.setVisibility(false);
  }

}
