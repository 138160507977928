import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate'
})
export class TruncatePipe implements PipeTransform {

  transform(value: string, ...args: number[]): unknown {
    const offset = args[0];
    const limit = args[1];
    const sliced = value.slice(offset, limit);
    return (offset > 0 ? '...' : '') + sliced + (value.length > limit ? '...' : '');
  }

}
