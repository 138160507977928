import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'kabin-alert-error',
  templateUrl: './alert-error.component.html',
  styleUrls: ['./alert-error.component.scss'],
})
export class AlertErrorComponent implements OnInit {

  @Input() error: HttpErrorResponse;
  @Output() closeAlert = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit() {}

  get isError() {
    return typeof this.error?.error === 'string';
  }

  get isValidation() {
    return typeof this.error?.error === 'object';
  }

  hideAlert() {
    this.closeAlert.emit(true);
  }

}
