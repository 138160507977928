import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';

import { Observable, throwError, from } from 'rxjs';
import { catchError, mergeMap, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { logout } from '../store/actions/auth.actions';
import { selectToken } from '../store/selectors/auth.selectors';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  constructor(
    private store: Store
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.store.select(selectToken).pipe(take(1)).pipe(mergeMap(token => {
      const cloneReq = this.addHeaders(request, token);
      return next.handle(cloneReq).pipe(
        catchError(error => {
          if (error.status === 401) {
            this.store.dispatch(logout());
          }
          return throwError(error);
        })
      );
    }));
  }

  private addHeaders(request: HttpRequest<any>, token: string) {
    const clone: HttpRequest<any> = request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      }
    });
    return clone;
  }
}
