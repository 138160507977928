import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'kabin-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
})
export class AlertComponent implements OnInit {

  @Input() color = 'default';
  @Input() icon: string;
  @Input() close: boolean;
  @Input() visible = true;
  @Output() closeAlert = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit() {
    this.close = this.close !== undefined;
  }

  hideAlert() {
    this.visible = false;
    this.closeAlert.emit(true);
  }

}
