import { createReducer, on } from "@ngrx/store";
import { Pod } from "src/app/types/pod";
import * as podActions from "../actions/pod.actions";

export interface PodState {
  pods: Pod[],
  display: 'now' | 'all',
  error: any,
  loading: boolean,
}

export const initialState: PodState = {
  pods: [],
  display: 'all',
  error: null,
  loading: false,
};

const onFailure = (state: PodState, { error }): PodState => {
  return {
    ...state,
    error,
    loading: false,
  }
}

const onSuccess = (state: PodState): PodState => {
  return {
    ...state,
    error: null,
    loading: false,
  }
}

const onLoading = (state: PodState): PodState => {
  return {
    ...state,
    error: null,
    loading: true,
  }
}

const onLoadPodsSuccess = (state: PodState, { pods }: { pods: Pod[]}): PodState => {
  return {
    ...state,
    pods,
    error: null,
    loading: true,
  }
}

const onTogglePodDisplay = (state: PodState): PodState => {
  return {
    ...state,
    display: state.display === 'all' ? 'now' : 'all',
  }
}

export const reducer = createReducer(
  initialState,
  on(
    podActions.loadPodsSuccess,
    onLoadPodsSuccess
  ),
  on(
    podActions.togglePodDisplay,
    onTogglePodDisplay
  ),
  on(
    podActions.loadPods,
    onLoading
  ),
  on(
    podActions.loadPodsSuccess,
    onSuccess
  ),
  on(
    podActions.loadPodsFailure,
    onFailure
  ),
);