import {
  ActionReducer,
  ActionReducerMap,
  MetaReducer
} from '@ngrx/store';
import * as fromAuth from 'src/app/store/reducers/auth.reducer';
import * as fromBooking from 'src/app/store/reducers/booking.reducer';
import * as fromLocalization from 'src/app/store/reducers/localization.reducer';
import * as fromRide from 'src/app/store/reducers/ride.reducer';
import * as fromPod from 'src/app/store/reducers/pod.reducer';
import * as fromMe from 'src/app/store/reducers/me.reducer';
import { DELETE_ACCOUNT_SUCCESS, LOGOUT } from './actions/auth.actions';
  
export interface AppState {
  auth: fromAuth.AuthState;
  booking: fromBooking.BookingState,
  localization: fromLocalization.LocalizationState,
  ride: fromRide.RideState,
  pod: fromPod.PodState,
  me: fromMe.MeState,
}

export const reducers: ActionReducerMap<AppState> = {
  auth: fromAuth.reducer,
  booking: fromBooking.reducer,
  localization: fromLocalization.reducer,
  ride: fromRide.reducer,
  pod: fromPod.reducer,
  me: fromMe.reducer,
};

const clear = (reducer: ActionReducer<AppState>): ActionReducer<AppState> => {
  return (state, action) => {
    return reducer([DELETE_ACCOUNT_SUCCESS, LOGOUT].includes(action.type) ? {
      ...state,
      auth: fromAuth.initialState,
      ride: fromRide.initialState,
      booking: fromBooking.initialState,
    } : state, action)
  };
};

export const metaReducers: MetaReducer<AppState>[] = [clear];