import { createSelector } from '@ngrx/store';
import { AppState } from '..';
import { BookingState } from '../reducers/booking.reducer';
import { Booking } from 'src/app/types/booking';
import { MeState } from '../reducers/me.reducer';

export const selectMeState = (state: AppState) => state.me;

export const selectNextBookings = createSelector(
  selectMeState,
  (state: MeState) => state.bookings.next
);

export const selectPastBookings = createSelector(
  selectMeState,
  (state: MeState) => state.bookings.past
);

export const selectBooking = (uuid: string) => createSelector(
  selectMeState,
  (state: MeState) => state.bookings.next?.find((booking: Booking) => booking.uuid === uuid)
)


export const selectMeError = createSelector(
  selectMeState,
  (state: MeState) => state.error
)