import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'kabin-pmr-popover',
  templateUrl: './pmr-popover.component.html',
  styleUrls: ['./pmr-popover.component.scss'],
})
export class PmrPopoverComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
