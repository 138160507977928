import { createSelector } from '@ngrx/store';
import { AppState } from '..';
import { PodState } from '../reducers/pod.reducer';

export const selecPodState = (state: AppState) => state.pod;

export const selectPods = createSelector(
  selecPodState,
  (state: PodState) => state.pods
);

export const selectPodDisplay = createSelector(
  selecPodState,
  (state: PodState) => state.display
);
