import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { clearAuthError, deleteAccount, getUserSuccess, logout } from 'src/app/store/actions/auth.actions';
import { selectError } from 'src/app/store/selectors/auth.selectors';
import { selectLocale, selectLocalization } from 'src/app/store/selectors/localization.selectors';

@Component({
  selector: 'kabin-account-delete',
  templateUrl: './delete.component.html',
  styleUrls: ['./delete.component.scss'],
})
export class AccountDeleteComponent implements OnInit {
  
  @Output() setAccountStep = new EventEmitter<Number>();

  public deleteKey: string = ''
  disableButton: boolean = true;
  protected deleteText: string = 'SUPPRIMER'
  protected alertHeader: string = 'Suppression du compte'
  protected alertMessage: string = 'Attention cette action est irréversible'
  protected alertButton: string = 'Annuler'
  protected alertButton2: string = 'Supprimer mon compte'
  error$: Observable<any>

  constructor(
    private store: Store,
    private alertController: AlertController
  ) {
    this.error$ = this.store.select(selectError)
    this.store.select(selectLocalization)
    this.store.select(selectLocale).subscribe(language => {
      if (language === 'fr') {
        this.deleteText = 'SUPPRIMER'
        this.alertHeader = 'Suppression du compte'
        this.alertMessage = 'Attention cette action est irréversible'
        this.alertButton = 'Annuler'
        this.alertButton2 = 'Supprimer mon compte'
      } else if (language === 'en') {
        this.deleteText = 'DELETE'
        this.alertHeader = 'Account delete'
        this.alertMessage = 'Warning, this action is irreversible'
        this.alertButton = 'Cancel'
        this.alertButton2 = 'Delete my account'
      }
    })
  }

  ngOnInit() {
  }

  async deleteAccount() {
    const alert = await this.alertController.create({
      header: this.alertHeader,
      message: this.alertMessage,
      buttons: [
        {
          text: this.alertButton,
          role: `cancel`,
          cssClass: `secondary`
        },
        {
          text: this.alertButton2,
          handler: () => {
            this.store.dispatch(deleteAccount())
          }
        }
      ]
    })
    await alert.present();
  }

  onInputChange(inputKey: string): void {
    if (inputKey.toUpperCase() === this.deleteText) {
      this.disableButton = false
    } else {
      this.disableButton = true
    }
  }

  cancelDelete() {
    this.setAccountStep.emit(1)
  }

  closeAlert() {
    this.store.dispatch(clearAuthError())
  }
}
