import { NgModule } from '@angular/core';

import { IonMaskDirective } from './ion-mask/ion-mask.directive';

const KABIN_DIRECTIVES = [
  IonMaskDirective,
];

@NgModule({
    declarations: [
        KABIN_DIRECTIVES
    ],
    exports: [
        KABIN_DIRECTIVES
    ]
})
export class DirectivesModule { }
