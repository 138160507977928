import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
// import PusherJS from 'pusher-js';
import PusherJS from 'pusher-js/with-encryption';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PusherService {

  pusher: any;
  channel: any;

  constructor(
    private http: HttpClient
  ) {
    this.pusher = new PusherJS(environment.pusher.key, {
        wsHost: environment.pusher.host,
        wsPort: environment.pusher.port,
        forceTLS: false,
        disableStats: true,
        enabledTransports: ['ws', 'wss']
    });

    this.channel = this.pusher.subscribe('test');
    if (environment.debug) {
      this.channel.bind_global((event: string, data: any) => {
        console.log(`The event ${event} was triggered with data`, data);
      });
    }

    this.channel = this.pusher.subscribe('pod.2');
    if (environment.debug) {
      this.channel.bind_global((event: string, data: any) => {
        console.log(`The event ${event} was triggered with data`, data);
      });
    }

    this.channel = this.pusher.subscribe('screen.3fa5a8f639ba1f29');
    if (environment.debug) {
      this.channel.bind_global((event: string, data: any) => {
        console.log(`The event ${event} was triggered with data`, data);
      });
    }

    this.channel = this.pusher.subscribe('screen.3a580637150be9d8');
    if (environment.debug) {
      this.channel.bind_global((event: string, data: any) => {
        console.log(`The event ${event} was triggered with data`, data);
      });
    }
    /*
    client.subscribe('test').bind('message', (message) => {
        alert(`${message.sender} says: ${message.content}`);
    });
    // this.channel = this.pusher.subscribe('events-channel');
    */
    
  }

  /*
  like( num_likes ) {
    this.http.post('http://localhost:3120/update', {'likes': num_likes})
      .subscribe(data => {});}
  }
  */

  subscribe(channel: string) {
    return this.pusher.subscribe(channel)
  }

  unsubscribe(channel: string) {
    return this.pusher.unsubscribe(channel)
  }
}
