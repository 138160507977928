import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { IonicModule } from '@ionic/angular';
import { TranslocoRootModule } from 'src/app/transloco-root.module';

import { DateFnsModule, DateFnsConfigurationService } from 'ngx-date-fns';
import { fr } from 'date-fns/locale';
import { ComponentsModule } from '../components/components.module';
import { DirectivesModule } from '../directives/directives.module';

import { BookingPage } from './booking/booking.page';
import { RidePage } from './ride/ride.page';
import { DocumentsPage } from './documents/documents.page';
import { ConfirmPage } from './confirm/confirm.page';
import { VersionPage } from './version/version.page';
import { PipesModule } from '../pipes/pipes.module';

const KABIN_COMPONENTS = [
  BookingPage,
  RidePage,
  DocumentsPage,
  ConfirmPage,
  VersionPage
];

const frenchConfig = new DateFnsConfigurationService();
frenchConfig.setLocale(fr);

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        DirectivesModule,
        IonicModule,
        DateFnsModule.forRoot(),
        DragDropModule,
        ComponentsModule,
        PipesModule,
        TranslocoRootModule,
    ],
    declarations: [
        KABIN_COMPONENTS
    ],
    providers: [
        { provide: DateFnsConfigurationService, useValue: frenchConfig },
    ],
    exports: [
        KABIN_COMPONENTS
    ]
})
export class ModalsModule { }
