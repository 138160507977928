import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'minutesToHours'
})
export class MinutesToHoursPipe implements PipeTransform {

  transform(totalMinutes: number, ...args: unknown[]): unknown {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;

    return `${hours ? (hours+'h'):''}${minutes > 0 ? ` ${minutes}m` : ''}`;
  }

}
