import { HttpErrorResponse } from '@angular/common/http';
import { createSelector } from '@ngrx/store';
import { AppState } from '..';
import { AuthState } from '../reducers/auth.reducer';

export const selectAuthState = (state: AppState) => state.auth;

export const selectConnectionStatus = createSelector(
  selectAuthState,
  (state: AuthState) => state.isConnected
);

export const selectError = createSelector(
  selectAuthState,
  (state: AuthState) => state.error
);

export const selectIsGuest = createSelector(
  selectAuthState,
  (state: AuthState) => !state.isConnected
);

export const selectConnectedUser = createSelector(
  selectAuthState,
  (state: AuthState) => state.user
);

export const selectToken = createSelector(
  selectAuthState,
  (state: AuthState) => state.token
);

export const selectAuthErrors = createSelector(
  selectAuthState,
  (state: AuthState) => state.errors
);

export const selectAuthLoading = createSelector(
  selectAuthState,
  (state: AuthState) => state.loading
);

export const selectAuthRegister = createSelector(
  selectAuthState,
  (state: AuthState) => state.register
);

export const selectAuthRegisterStep = createSelector(
  selectAuthRegister,
  (state) => state.step
);

export const selectAuthRegisterCountry = createSelector(
  selectAuthRegister,
  (state) => state.country
);

export const selectAuthRegisterPhone = createSelector(
  selectAuthRegister,
  (state) => state.phone
);

export const selectAuthError = createSelector(
  selectAuthState,
  (state) => state.error
);

export const selectInitialLoginAttempted = createSelector(
  selectAuthState,
  (state) => {
    return {
      user: state.user,
      isReady: state.initialLoginAttempted,
    }
  }
);